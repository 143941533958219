import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import avatar from '../../assets/images/avatar.svg';
import DrewsModal from "../../components/modal/DrewsModal";
import { useDispatch, useSelector } from "react-redux";
import { createNewTicket, getAllTickets, manageTicket } from "../../redux/slices/support.slice";
import Swal from "sweetalert2";
import { clearMessage } from "../../redux/slices/message";
import { useNavigate } from "react-router-dom";
import dateFormatter from "../../utils/dateFormatter";
import formatCurrency from "../../utils/currencyFormmter";


const Tickets = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()


    const [modalShow, setModalShow] = useState(false)
    const [showForm, setForm] = useState(false)
    const [tickets, setTickets] = useState([])
    const [transaction, setTransaction] = useState({})
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({page: 1})
    const [errors, setErrors] = useState({})
    const [attachment, setAttachment] = useState(null)
    const [analytics, setAnalytics] = useState({})
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})

    useEffect(() => {
        loadTickets()
    }, [payload.page])


    const handleInput = (event) => {
        const { name, value } = event.target;
        if(name === 'save_beneficiary') {
            let val = event.target.checked ? 'yes' : 'no'
            setPayload((prevState) => ({...prevState, [name]: val}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const { name, value } = event.target

        const regex = /^\d*\.?\d*$/;

        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && !regex.test(value)) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }


    const loadTickets = () => {
        dispatch(getAllTickets(payload.page))
        .then((res) => {
            if(res.payload?.results) {
                setTickets(res.payload.results)
            }
            if(res.payload?.analytics) {
                setAnalytics(res.payload.analytics)
            }
            if(res.payload?.next || res.payload?.previous) {
                const data = {
                    next: res.payload?.next,
                    prev: res.payload?.previous,
                }
                setpaginationData(data)
            }
        })
    }

    const submitTicket = () => {
        let formdata = new FormData()
        formdata.append('attachment', attachment)
        formdata.append('desk', payload.department)
        formdata.append('message', payload.message)
        formdata.append('subject', payload.subject)
        dispatch(createNewTicket(formdata))
        .then((response) => {
            if(response.payload?.success) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                }).then(() => setForm(false))
            }
            else if(response.payload?.message) {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
            loadTickets()
        })
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            setAttachment(reader.result);
        };
    }

    const resolveTicket = (ticketID, status) => {
        setLoading(true)
        dispatch(manageTicket({ticket_id: ticketID, status: status}))
        .then((response) => {
            if(response?.payload?.success === true) {
                Swal.fire({
                    title: 'Success',
                    text: response?.payload?.message,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            }
            else {
                Swal.fire({
                    title: 'Failed',
                    text: response?.payload?.message || 'An error occurred',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
        .finally(() => {
            setLoading(false)
            loadTickets()
        })
    }

    const manageSupportTicket = (ticketID, status) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to mark this ticket as ${status}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })
        .then((result) => {
            if(result.isConfirmed) {
                resolveTicket(ticketID, status)
            }
        })
    }

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    return (
        <Layout title="Support Tickets">
            <Container className="mt-4 px-4 mb-4">

                <div className="mt-4">
                    <div className="mb-3 mt-3 analytic-row">
                        <div className="shadow-sm border-0 card bg--primary">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon ">
                                </div>
                                <div>
                                    <h3 className="m-0 text-primary font-bold">{formatCurrency(analytics?.total_tickets).formatted}</h3>
                                    <p className="text-muted text-sm m-0">Total Tickets</p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3 className="m-0 text-dark font-bold">{formatCurrency(analytics?.total_opened_tickets).formatted}</h3>
                                    <p className="text-muted text-sm m-0">Open Tickets</p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon bg-light">
                                </div>
                                <div>
                                    <h3 className="m-0 text-success font-bold">{formatCurrency(analytics?.total_resolved_tickets).formatted}</h3>
                                    <p className="text-muted text-sm m-0">Answered</p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3 className="m-0 text-warning font-bold">{formatCurrency(analytics?.total_closed_tickets).formatted}</h3>
                                    <p className="text-muted text-sm m-0">Closed Tickets</p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3 className="m-0 text-secondary font-bold">{formatCurrency(analytics?.total_pending_tickets).formatted}</h3>
                                    <p className="text-muted text-sm m-0">Pending Tickets</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Card className="border-0 shadow-sm mb-3">
                        <Card.Body className="table-responsive-lg table-responsive">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Ticket ID</th>
                                        <th>Subject</th>
                                        <th>Handled by</th>
                                        <th>Date Created</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ? (
                                            <div className="in-page-loader">
                                                <Spinner size="lg" variant="primary"/>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        tickets?.length ? (
                                            tickets?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                       <button className="btn-plain text-primary font-bold" onClick={() => navigate(`/tickets/${item.ticketID}`)}>
                                                            #{item.ticketID}
                                                        </button>  
                                                    </td>
                                                    <td>
                                                        <div className="m-0 d-flex gap-2 align-items-center">
                                                            <strong className="text-dark">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10 0L20 10L10 20L0 10L10 0Z" fill="#EB5757"/>
                                                                </svg>
                                                                Complaint,
                                                            </strong>
                                                            <span>
                                                                {item.subject}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {item.desk || 'Nil'}
                                                    </td>
                                                    <td>
                                                        {dateFormatter(item.date)}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item?.status.toLowerCase() === 'success' || item.status.toLowerCase() === 'opened' ? 'badge-active' : 
                                                                (item?.status.toLowerCase() === 'failed' ? 'badge-failed' : 
                                                                (item?.status.toLowerCase() === 'resolved' || item?.status.toLowerCase() === 'closed' ? 'badge-used' : 'badge-pending'))} 
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.status.toLowerCase() === 'success' || item.status.toLowerCase() === 'opened' ? "#008000" : 
                                                                        (item.status.toLowerCase() === 'failed' ? "#EB5757" : 
                                                                        (item.status.toLowerCase() === 'resolved' || item?.status.toLowerCase() === 'closed' ? "#828282" : "#FAB941"))
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.status}
                                                                </span>
                                                            </Badge> 
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => navigate(`/tickets/${item.ticketID}`)}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Ticket
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="d-flex align-items-center gap-1"
                                                                        onClick={ () => manageSupportTicket(item.ticketID, 'Resolved') }
                                                                    >
                                                                        <FontAwesomeIcon icon="folder-closed" /> Mark as resolved
                                                                    </Dropdown.Item>
                                                                    {
                                                                        item.status !== 'Closed' ? (
                                                                            <>
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item 
                                                                                    eventKey={2} 
                                                                                    className="d-flex align-items-center text-danger gap-1"
                                                                                    onClick={ () => manageSupportTicket(item.ticketID, 'Closed') }
                                                                                >
                                                                                    <FontAwesomeIcon icon="file-zipper" /> Close Ticket
                                                                                </Dropdown.Item>
                                                                            </>
                                                                        ) : null
                                                                    }
                                                                    {
                                                                        item.status !== 'Pending' && item.status !== 'Opened' ? (
                                                                            <>
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item 
                                                                                    eventKey={2} 
                                                                                    className="d-flex align-items-center text-success gap-1"
                                                                                    onClick={ () => manageSupportTicket(item.ticketID, 'Opened') }
                                                                                >
                                                                                    <FontAwesomeIcon icon="folder-open" /> Open Ticket
                                                                                </Dropdown.Item>
                                                                            </>
                                                                    ) : null
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        : (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted mt-4">No data available</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        tickets?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next} 
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
            </Container>
        </Layout>
    );
}

export default Tickets;