import ServiceHelper from "./service.helper";

export default class SupportService {

    static createTicket(payload) {
        return ServiceHelper.handlePostRequest('/adminapi/support/', payload)
    }

    static replyTicket(payload) {
        return ServiceHelper.handlePostRequest('/adminapi/support/ticket_response/', payload)
    }

    static getTickets(page) {
        return ServiceHelper.handleGetRequest(`/adminapi/support/?page=${page}`)
    }

    static getTicket(id) {
        return ServiceHelper.handleGetRequest(`/adminapi/support/${id}`)
    }

    static getTicketReplies(id) {
        return ServiceHelper.handleGetRequest(`/adminapi/support/?action=replies&ticket_id=${id}`)
    }

    static markResolved(params) {
        return ServiceHelper.handlePatchRequest(`/adminapi/support/${params.ticket_id}`, {status: params.status})
    }
}