import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Dropdown } from "react-bootstrap";
import dateFormatter from "../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { manageTicket } from "../redux/slices/support.slice";
import Swal from "sweetalert2";

const SupportTicket = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const resolveTicket = (ticketID, status) => {
        dispatch(manageTicket({ticket_id: ticketID, status: status}))
        .then((response) => {
            if(response?.payload?.success === true) {
                Swal.fire({
                    title: 'Success',
                    text: response?.payload?.message,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            }
            else {
                Swal.fire({
                    title: 'Failed',
                    text: response?.payload?.message || 'An error occurred',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    const manageSupportTicket = (ticketID, status) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to mark this ticket as ${status}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })
        .then((result) => {
            if(result.isConfirmed) {
                resolveTicket(ticketID, status)
            }
        })
    }

    return (
        <tbody>
            {
                props.data?.length ? (
                    props.data?.map((item, index) => (
                        <tr key={index} className="d-flex align-items-center">
                            <td>
                                #{item.ticketID}
                            </td>
                            <td>
                                <div className="m-0 d-flex gap-2 align-items-center">
                                    <strong className="text-dark">
                                        {/* <img src={diamond} alt="diamond" width={15} /> */}
                                       <span className="ms-1">Complaint,</span> 
                                    </strong>
                                    <span>
                                        {item.subject}
                                    </span>
                                    <Dropdown className="mini-dropdown ms-auto">
                                        <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                            <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "15px"}} className="text-muted"/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                            <Dropdown.Item 
                                                eventKey={1} 
                                                className="text-primary d-flex align-items-center gap-1" 
                                                onClick={() => navigate(`/tickets/${item.ticketID}`)}
                                            >
                                            <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Ticket
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item 
                                                eventKey={2} 
                                                className="d-flex align-items-center gap-1"
                                                onClick={ () => manageSupportTicket(item.ticketID, 'Resolved') }
                                            >
                                                <FontAwesomeIcon icon="folder-closed" /> Mark as resolved
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                                <Dropdown.Item 
                                                    eventKey={2} 
                                                    className="d-flex align-items-center gap-1 text-danger"
                                                    onClick={ () => manageSupportTicket(item.ticketID, 'Closed') }
                                                >
                                                <FontAwesomeIcon icon="file-zipper" /> Close Ticket
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </td>
                            {
                                props.show_handler ? 
                                    <td>
                                        {item.desk || 'Nil'}
                                    </td>
                                : null
                            }
                            {
                                props.show_date ? 
                                    <td>
                                        {dateFormatter(item.date)}
                                    </td>
                                : null
                            }
                            {
                                props.show_status ?  (
                                    <>
                                        <td>
                                            <div className="d-flex m-0 align-items-center justify-content-between">
                                            <Badge 
                                                className={item?.status.toLowerCase() === 'success' ? 'badge-active' : 
                                                (item?.status.toLowerCase() === 'failed' ? 'badge-failed' : 
                                                (item?.status.toLowerCase() === 'resolved' ? 'badge-used' : 'badge-pending'))}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                    <circle cx="5" cy="5" r="5" fill={
                                                        item.status.toLowerCase() === 'success' ? "#008000" : 
                                                        (item.status.toLowerCase() === 'failed' ? "#EB5757" : 
                                                        (item.status.toLowerCase() === 'resolved' ? "#828282" : "#FAB941"))
                                                    }
                                                    />
                                                </svg>
                                                <span className="ps-1">
                                                    {item.status}
                                                </span>
                                            </Badge> 
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                    <Dropdown.Item 
                                                        eventKey={1} 
                                                        className="text-primary d-flex align-items-center gap-1" 
                                                        onClick={() => navigate(`/tickets/${item.ticketID}`)}
                                                    >
                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Ticket
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item 
                                                        eventKey={2} 
                                                        className="d-flex align-items-center gap-1"
                                                        >
                                                        <FontAwesomeIcon icon="folder-open" /> Mark as resolved
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item 
                                                        eventKey={2} 
                                                        className="d-flex align-items-center gap-1 text-danger"
                                                        >
                                                        <FontAwesomeIcon icon="file-zipper" /> Close Ticket
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            </div>
                                        </td>
                                    </>
                                ) : null
                            }
                        </tr>
                    ))
                )
                : (
                    <tr>
                        <td colSpan={5} className="text-center text-muted mt-4">No data available</td>
                    </tr>
                )
            }
        </tbody>
    );
}

export default SupportTicket;