import { Col, Container, Card, Spinner } from "react-bootstrap";
import Layout from "../components/Layout";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getEarnings } from "../redux/slices/account.service";
import pound from '../assets/images/pound.svg';
import pound2 from '../assets/images/pound3.svg';
import list from '../assets/images/list.svg';
import list1 from '../assets/images/list1.svg';
import list3 from '../assets/images/list3.svg';
import list4 from '../assets/images/list4.svg';

import {
    Chart as ChartJS,
    Title,
    LineElement,
    PointElement,
    Tooltip,
    Filler,
    LinearScale,
    CategoryScale
  } from 'chart.js';
import { Line } from "react-chartjs-2";
import formatCurrency from "../utils/currencyFormmter";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler
);

const Earning = () => {

    const isLoaded = useRef(false)


    const dispatch = useDispatch()

    const [results, setResults] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [loading, setLoading] = useState(false)

    const groupedData = results.reduce((acc, transaction) => {
        const date = new Date(transaction.date);
        const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`; // Format as YYYY-MM
        const type = transaction.transaction_type;
    
        if (!acc[type]) acc[type] = {};
        if (!acc[type][month]) acc[type][month] = 0;
    
        acc[type][month] += transaction.amount;
    
        return acc;
      }, {});
    
    const allMonths = Array.from(
    new Set(results.map((t) => new Date(t.date).toISOString().slice(0, 7)))
    ).sort();

    const datasets = Object.entries(groupedData).map(([type, data], index) => ({
    label: type,
    data: allMonths.map((month) => data[month] || 0), 
    borderColor: `hsl(${index * 60}, 70%, 50%)`,
    backgroundColor: `hsl(${index * 60}, 70%, 80%)`,
    pointBorderColor: `hsl(${index * 60}, 70%, 50%)`,
    pointBackgroundColor: "#fff",
    borderWidth: 2,
    tension: 0.4,
    }));

    const chartData = {
    labels: allMonths,
    datasets,
    };

    const options = {
    responsive: true,
    plugins: {
        legend: { display: true, position: "top" },
        tooltip: { enabled: true },
    },
    scales: {
        x: {
        title: { display: true, text: "Month" },
        },
        y: {
        title: { display: true, text: "Amount" },
        beginAtZero: true,
        },
    },
    };

    useEffect(() => {
        if(!isLoaded.current) {
            loadEarnings()
            isLoaded.current = true
        }
    }, [])

    const loadEarnings = () =>  {
        setLoading(true)
        dispatch(getEarnings({}))
        .then((response) => {
            if(response.payload.results) {
                setResults(response.payload.results)
            }
            
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
        })
        .finally(() => setLoading(false))
    }
 
    return (
        <Layout title="Earnings">
            <Container className="mt-4 px-4 mb-4">
                <div className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    <Col md={12} className="mb-3 mt-3 analytic-row earnings">
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon ">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.overall_earnings).with_currency}</h3>
                                    <p>
                                        <img src={pound} alt="pound" />
                                        Overall Total Earning
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon bg-light">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_withdrawal_earnings).with_currency}</h3>
                                    <p>
                                        <img src={pound2} alt="pound" />
                                        Total Gross Earning (withdrawal)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_paycode_generation_earnings).with_currency}</h3>
                                    <p>
                                        <img src={list4} alt="pound" />
                                        Total Gross Earning (Paycode)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_p2p_earnings).with_currency}</h3>
                                    <p>
                                        <img src={list} alt="list" />
                                        Total P2P Earning (P2P)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_loyalty_voucher_earning).with_currency}</h3>
                                    <p>
                                        <img src={list1} alt="list" />
                                        Total Loyalty Voucher Earnings
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_bank_transfer_earnings).with_currency}</h3>
                                    <p>
                                        <img src={list3} alt="list" />
                                        Total Bank Transfer Earning
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_faaspay_earnings).with_currency}</h3>
                                    <p>
                                        <img src={pound} alt="list" />
                                        Total FaasPay Earnings
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_subscription_earnings).with_currency}</h3>
                                    <p>
                                        <img src={pound2} alt="list" />
                                        Total Subcription Earning
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-sm border-0 card">
                            <div className="d-flex align-items-center gap-2 px-2 py-4">
                                <div className="icon">
                                </div>
                                <div>
                                    <h3>{formatCurrency(analytics?.total_bills_payment_earnings).with_currency}</h3>
                                    <p>
                                        <img src={list4} alt="list" />
                                        Total Bills Payment Earning
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Card className="border-0 shadow-sm">
                        <Card.Header className="bg-white border-0">VEND CHART</Card.Header>
                        <Card.Body>
                            <Line options={options} data={chartData} />
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
}

export default Earning;