import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/main.scss';

import { library } from '@fortawesome/fontawesome-svg-core'

import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Signup from './pages/auth/Signup';
import ProfileSetup from './pages/customer/ProfileSetup';
import Reports from './pages/Reports';
import Transactions from './pages/Transactions';
import Tickets from './pages/ticket';
import TicketView from './pages/ticket/View';
import Invoices from './pages/invoice';
import Paycode from './pages/paycode';
import Voucher from './pages/voucher';
import BillsPayment from './pages/billspayment';
import Wallets from './pages/wallets';
import P2pPayment from './pages/P2pPayment';
import Subscriptions from './pages/subscription';
import Plans from './pages/subscription/Plans';
import Customers from './pages/customer';
import Ratings from './pages/rating';
import Businesses from './pages/business';
import Messaging from './pages/messaging';
import Settings from './pages/settings';
import Payments from './pages/Payments';
import Sms from './pages/messaging/Sms';
import UniversalPaycode from './pages/paycode/UniversalPaycode';
import QrPayment from './pages/QrPayment';
import Announcement from './pages/Announcement';
import Audit from './pages/Audit';
import BusinessDetails from './pages/business/BusinessDetails';
import Profile from './pages/settings/Profile';
import AdminManagement from './pages/settings/AdminManagement';
import WithdrawalLimit from './pages/settings/WithdrawalLimit';
import TransferFees from './pages/settings/TransferFees';
import TransferLimit from './pages/settings/TransferLimit';
import Earning from './pages/Earning';
import TransactionHistory from './pages/business/TransactionHistory';
import LoyaltyPaycode from './pages/paycode/LoyaltyPaycode';
import { useSelector } from 'react-redux';
import ExchangeRates from './pages/settings/ExchangeRates';

const privateRoutes = [
  { path: '/', role: '', authRequired: false, exact: true, component: Login },
  { path: '/forgot-password', role: '', authRequired: false, exact: true, component: ForgotPassword },
  { path: '/reset-password', role: '', authRequired: false, exact: true, component: ResetPassword },
  { path: '/dashboard', role: "admin", authRequired: true, exact: true, adminRoute: Home },
  { path: '/transactions', role: "admin", authRequired: true, exact: true, adminRoute: Transactions },
  { path: '/transactions/:merchant_id', role: "admin", authRequired: true, exact: true, adminRoute: TransactionHistory },
  { path: '/paycodes', role: "admin", authRequired: true, exact: true, adminRoute: Paycode },
  { path: '/tickets', role: "admin", authRequired: true, exact: true, adminRoute: Tickets },
  { path: '/tickets/:id', role: "admin", authRequired: true, exact: true, adminRoute: TicketView },
  { path: '/bills-payment', role: "admin", authRequired: true, exact: true, adminRoute: BillsPayment },
  { path: '/p2p-payment', role: "admin", authRequired: true, exact: true, adminRoute: P2pPayment },
  { path: '/loyalty-vouchers', role: "admin", authRequired: true, exact: true, adminRoute: LoyaltyPaycode },
  { path: '/businesses', role: "admin", authRequired: true, exact: true, adminRoute: Businesses },
  { path: '/businesses/:id', role: "admin", authRequired: true, exact: true, adminRoute: BusinessDetails },
  { path: '/messaging', role: "admin", authRequired: true, exact: true, adminRoute: Messaging },
  { path: '/messaging/sms', role: "admin", authRequired: true, exact: true, adminRoute: Sms },
  { path: '/announcements', role: "admin", authRequired: true, exact: true, adminRoute: Announcement },
  { path: '/profile', role: "admin", authRequired: true, exact: true, adminRoute: Profile },
  { path: '/admin-management', role: "admin", authRequired: true, exact: true, adminRoute: AdminManagement },
  { path: '/withdrawal-limits', role: "admin", authRequired: true, exact: true, adminRoute: WithdrawalLimit },
  { path: '/transfer-fees', role: "admin", authRequired: true, exact: true, adminRoute: TransferFees },
  { path: '/audit-trails', role: "admin", authRequired: true, exact: true, adminRoute: Audit },
  { path: '/transfer-limits', role: "admin", authRequired: true, exact: true, adminRoute: TransferLimit },
  { path: '/subscription-management', role: "admin", authRequired: true, exact: true, adminRoute: Subscriptions },
  { path: '/earnings', role: "admin", authRequired: true, exact: true, adminRoute: Earning },
  { path: '/exchange-rates', role: "admin", authRequired: true, exact: true, adminRoute: ExchangeRates },
];

function App() {
  const { isAuthenticated } = useSelector(state => state.auth);

  return (
    <Routes>
      {
        privateRoutes.map(route => (
          <Route 
            path={route.path}
            key={route.path}
            exact={route.exact}
            element={
              !isAuthenticated && route.authRequired ? <Navigate to={'/'}/> :
              !route.authRequired ? <route.component/> : 
              isAuthenticated && route.role === 'admin' ? <route.adminRoute/> :
              <Navigate to={'/'}  replace/>
            }
          />
        ))
      }
    </Routes>
  );
}

export default App;
library.add(fab, fas, far)