export const menus = [
    {
        "name": "Dashboard",
        "url": "/dashboard",
        "icon": "lni lni-grid-alt",
        "icon_type": "lni",
        "alias": "dashboard",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "Businesses",
        "url": "/businesses",
        "icon": "fa fa fa-building",
        "icon_type": "fa",
        "alias": "businesses",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "Transactions",
        "url": "/transactions",
        "icon": "fa fa fa-receipt",
        "icon_type": "fa",
        "alias": "transactions",
        "auth_required": true,
        "user_type": "manager",
    },
    // {
    //     "name": "Reports",
    //     "url": "/reports",
    //     "icon": "fa fa-chart-pie",
    //     "icon_type": "fa",
    //     "alias": "reports",
    //     "auth_required": true,
    //     "user_type": "manager",
    // },
    {
        "name": "Support & Ticketing",
        "url": "/tickets",
        "icon": "fa fa-headset",
        "icon_type": "fa",
        "alias": "support_tickets",
        "auth_required": true,
        "user_type": "manager",
    },
    // {
    //     "name": "Rewards",
    //     "url": "/rewards",
    //     "icon": "fa fa-gift",
    //     "icon_type": "fa",
    //     "alias": "rewards",
    //     "auth_required": true,
    //     "user_type": "manager",
    // },
    {
        "name": "FEATURES",
        "children": [
            {
                "name": "Paycodes Payment",
                "url": "/paycodes",
                "icon": "fa fa-money-check",
                "icon_type": "fa",
                "alias": "paycodes",
                "auth_required": true,
                "user_type": "manager",
            },
            // {
            //     "name": "Invoice",
            //     "url": "/invoices",
            //     "icon": "fa fa-receipt",
            //     "icon_type": "fa",
            //     "alias": "invoices",
            //     "auth_required": true,
            //     "user_type": "manager",
            // },
            // {
            //     "name": "Vouchers",
            //     "url": "/vouchers",
            //     "icon": "fa fa-tag",
            //     "icon_type": "fa",
            //     "alias": "vouchers",
            //     "auth_required": true,
            //     "user_type": "manager",
            // },
            {
                "name": "P2P",
                "url": "/p2p-payment",
                "icon": "fa fa-arrow-right-arrow-left",
                "icon_type": "fa",
                "alias": "p2p",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Bills Payment",
                "url": "/bills-payment",
                "icon": "fa fa-receipt",
                "icon_type": "fa",
                "alias": "bills_payment",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Loyalty Vouchers",
                "url": "/loyalty-vouchers",
                "icon": "fas fa-gifts",
                "icon_type": "fa",
                "alias": "loyalty-vouchers",
                "auth_required": true,
                "user_type": "manager",
            },
        ]
    },
    {
        "name": "SUPPORT",
        "children": [
            // {
            //     "name": "Customers",
            //     "url": "/customers",
            //     "icon": "lni lni-users",
            //     "icon_type": "lni",
            //     "alias": "customers",
            //     "auth_required": true,
            //     "user_type": "manager",
            // },
            {
                "name": "SMS Marketing",
                "url": "/messaging/sms",
                "icon": "fa fa-comment",
                "icon_type": "fa",
                "alias": "sms",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Email Marketing",
                "url": "/messaging",
                "icon": "lni lni-envelope",
                "icon_type": "lni",
                "alias": "email",
                "auth_required": true,
                "user_type": "manager",
            },
            // {
            //     "name": "Customer's Rating",
            //     "url": "/ratings",
            //     "icon": "fa fa-star",
            //     "icon_type": "fa",
            //     "alias": "ratings",
            //     "auth_required": true,
            //     "user_type": "manager",
            // },
            // {
            //     "name": "Business Inco. request",
            //     "url": "/business-request",
            //     "icon": "lni lni-users",
            //     "icon_type": "lni",
            //     "alias": "business_request",
            //     "auth_required": true,
            //     "user_type": "manager",
            // },
            {
                "name": "Announcements",
                "url": "/announcements",
                "icon": "fa fa-bullhorn",
                "icon_type": "fa",
                "alias": "announcements",
                "auth_required": true,
                "user_type": "manager",
            },
        ]
    },
    {
        "name": "SETTINGS",
        "children": [
            // {
            //     "name": "Settings",
            //     "url": "/settings",
            //     "icon": "lni lni-cog",
            //     "icon_type": "lni",
            //     "alias": "settings",
            //     "auth_required": true,
            //     "user_type": "admin",
            // },
            {
                "name": "Admin Profile",
                "url": "/profile",
                "icon": "fa fa-user",
                "icon_type": "fa",
                "alias": "profile",
                "auth_required": true,
                "user_type": "admin",
            },
            {
                "name": "Admin Management",
                "url": "/admin-management",
                "icon": "fa fa-users",
                "icon_type": "fa",
                "alias": "admin-management",
                "auth_required": true,
                "user_type": "admin",
            },
            {
                "name": "Withdrawal Limits",
                "url": "/withdrawal-limits",
                "icon": "fa fa-wallet",
                "icon_type": "fa",
                "alias": "withdrawal-limits",
                "auth_required": true,
                "user_type": "admin",
            },
            {
                "name": "Transfer Fees",
                "url": "/transfer-fees",
                "icon": "fa fa-dollar-sign",
                "icon_type": "fa",
                "alias": "transfer-fees",
                "auth_required": true,
                "user_type": "admin",
            },
            {
                "name": "Exchange Rates",
                "url": "/exchange-rates",
                "icon": "fa fa-coins",
                "icon_type": "fa",
                "alias": "exchange-rates",
                "auth_required": true,
                "user_type": "admin",
            },
            {
                "name": "Audit Trails",
                "url": "/audit-trails",
                "icon": "fa fa-book",
                "icon_type": "fa",
                "alias": "audit-trail",
                "auth_required": true,
                "user_type": "admin",
            },
            {
                "name": "Transfer Limits",
                "url": "/transfer-limits",
                "icon": "fa fa-wallet",
                "icon_type": "fa",
                "alias": "transfer-limits",
                "auth_required": true,
                "user_type": "admin",
            },
            {
                "name": "Subscription Mgt. & Fees",
                "url": "/subscription-management",
                "icon": "fa fa-shop",
                "icon_type": "fa",
                "alias": "subscription-management",
                "auth_required": true,
                "user_type": "admin",
            },
            {
                "name": "Earnings",
                "url": "/earnings",
                "icon": "pound3",
                "icon_type": "svg",
                "alias": "earnings",
                "auth_required": true,
                "user_type": "admin",
            },
            // {
            //     "name": "Managers",
            //     "url": "/managers",
            //     "icon": "lni lni-user",
            //     "icon_type": "lni",
            //     "alias": "managers",
            //     "auth_required": true,
            //     "user_type": "manager",
            // },
        ]
    },
]