import { Row, Col, Container, Card, Form, Button, Spinner, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTransferCharges, saveTransferCharges } from "../../redux/slices/account.service";
import Swal from "sweetalert2";

const TransferFees = () => {

    const dispatch = useDispatch()

    const initialState = {
        charges: [],
        payload: {}
    }

    const [loading, setLoading] = useState(false)
    const [show_edit, setShowEdit] = useState(false)
    const [state, setState] = useState(initialState)
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        loadFees()
    }, [0])
 

    const loadFees = () =>  {
        dispatch(getTransferCharges())
        .then((response) => {
            if(typeof(response.payload) === 'object') {
                setState({...state, charges: response.payload})
            }
        })
    }

    const handleChargesUpdate = () => {
        Swal.fire({
            icon: 'warning',
            text: `Are you sure you want to apply changes?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Proceed',
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                dispatch(saveTransferCharges(state.payload))
                .then((response) => {
                    if(response.payload?.success && response.payload?.success === true) {
                        Swal.fire({
                            icon: 'success',
                            text: `Transfer charges updated successfully.`,
                        })
                        loadFees()
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.payload?.message ?? `Failed to update transfer charges.`,
                        })
                    }
                })
                .finally(() => setLoading(false))
            }
        })
    }
 
    return (
        <Layout title="Transaction Fees">
            <Container className="mt-4 px-4 px-lg-4 px-sm-2 px-md-2 mb-4">
                <div className="mt-4">
                    <Card className="border-0 shadow-sm mt-5">
                        <Card.Header className="d-flex align-items-center justify-content-between bg-white border-0">
                            <h5>Transfer Charges</h5>
                            <button 
                                type="button" 
                                className="btn-plain text-primary text-sm bg---secondary rounded-1 font-bold px-3 py-2"
                                onClick={ () => setEditMode(!editMode)}
                            >
                                Edit Details
                            </button>
                        </Card.Header>
                        <Card.Body className="table-responsive-sm">
                           <div className="table-responsive border rounded-1">
                            <table className="table table-borderless table-sm">
                                <thead className="border-bottom">
                                    <tr>
                                        <th>Transaction Type</th>
                                        <th>Transaction Charge(capped)</th>
                                        <th>Transaction Charge(%)</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.charges && (
                                            state.charges.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.name.toUpperCase().split('_').join(' ')}</td>
                                                    <td>{item.capped}</td>
                                                    <td>
                                                        {item.value} {item.name !== 'loyalty_voucher' && item.name !== 'loyalty_voucher_top_up' ? '%' : '' }
                                                    </td>
                                                    <td>
                                                        {
                                                            editMode && (
                                                                <div className="input-group w-50">
                                                                    <input 
                                                                        className="form-control form-control-sm"
                                                                        type="number" 
                                                                        placeholder={item.name} 
                                                                        min={0}
                                                                        name={item.name}
                                                                        value={item.value}
                                                                        onChange={(e) => {
                                                                            const updatedCharges = [...state.charges];
                                                                            updatedCharges[index].value = e.target.value; 
                                                                            setState((prevState) => ({
                                                                                ...prevState,
                                                                                charges: updatedCharges,
                                                                                payload: { 
                                                                                    ...prevState.payload, 
                                                                                    amount: e.target.value,
                                                                                    name:  item.name
                                                                                }
                                                                            }));
                                                                        }}
                                                                    />
                                                                    <div className="input-group-appennd">
                                                                        <Button 
                                                                            variant="primary" 
                                                                            type="button"
                                                                            size="sm"
                                                                            className="input-group-text"
                                                                            onClick={handleChargesUpdate}
                                                                            disabled={item.name !== state.payload.name}
                                                                        > Save <Spinner animation={ loading && item.name === state.payload.name  ? "border" : null} role="status" size="sm"></Spinner>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }
                                </tbody>
                            </table>
                           </div>
                        </Card.Body>
                    </Card>
                </div>
                <Modal 
                    show={show_edit}
                    onHide={ () => setShowEdit(!show_edit)}
                >
                    <Modal.Header closeButton className="p-2">
                        <h6 className="modal-title">Transfer Charges</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row className="mb-2">
                                {
                                    state.charges && (
                                        state.charges.map((item, index) => (
                                            <Form.Group className="mb-3" as={Col} md={6} key={index}>
                                                <Form.Label>{item.name.toUpperCase()}</Form.Label>
                                                <Form.Control 
                                                    type="number" 
                                                    placeholder={item.name} 
                                                    min={0}
                                                    name={item.name}
                                                    value={item.value}
                                                    onChange={(e) => {
                                                        const updatedCharges = [...state.charges];
                                                        updatedCharges[index].value = e.target.value; 
                                                        setState((prevState) => ({
                                                            ...prevState,
                                                            charges: updatedCharges,
                                                            payload: { 
                                                                ...prevState.payload, 
                                                                [item.name]: e.target.value
                                                            }
                                                        }));
                                                    }}
                                                />
                                            </Form.Group>
                                        ))
                                    )
                                }
                            </Row>
                            <div className="d-grid gap-2">
                                <Button 
                                    variant="primary" 
                                    type="button"
                                    className="d-block"
                                    onClick={handleChargesUpdate}
                                > Save Changes <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default TransferFees;